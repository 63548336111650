import { useState } from 'react';

const Alert = ({ alerts }) => {
  const [on, setOn] = useState(true);
  if (!on) return null;
  const alert = alerts?.[0]?.[1];
  if (!alert) return null;
  const { title } = alert;
  let { message } = alert;
  // NOTE: for devise
  if (!title && !message) message = alert;

  const offModal = () => setOn(false);
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className='c-modal__overlay' onClick={offModal}>
      <div className='c-modal__wrap' onClick={stopPropagation}>
        <div className='c-modal__title'>{title}</div>
        <div className='c-modal__message'>{message}</div>
        <nav onClick={offModal} className='c-btnMain-standard c-btnSize-50 c-modal__button c-modal__button-close'>
          閉じる
        </nav>
        <div onClick={offModal} className='c-modal__close-icon'>
          ×
        </div>
      </div>
    </div>
  );
};

export default Alert;
